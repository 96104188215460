//import { find, head, isArray } from "lodash";
import { find, head } from "lodash";
import ApiService from "@/core/services/api.service";

export const GetContact = (type, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/${type}/${uuid}`)
			.then(({ data }) => {
				// const supplier = SetContact(data);
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetSupplier = (type, uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`supplier/contact/${type}/${uuid}`)
			.then(({ data }) => {
				// const supplier = SetContact(data);
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetCustomerList = (type, search) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`contact/${type}/list`, { search })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetContactPersons = (customerUuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact-person/${customerUuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetCustomer = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/customer/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetVendor = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`supplier/contact/supplier/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetProperty = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`property/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetContactProperty = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`property/${uuid}/contact`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ValidateCompanyName = (url, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(url, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const ValidateContact = (type, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`validate/${type}/all-field`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateCustomer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("contact/customer", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateCustomer = (params) => {
	if (params.uuid) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.put("contact/customer", params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post("contact/customer", params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	}
};

export const CreateOrUpdateCustomerProperty = (customerUuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${customerUuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateVendor = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("contact/vendor", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateVendor = (params) => {
	if (params.uuid) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.put("supplier/contact/supplier", params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post("supplier/contact/supplier", params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	}
};

export const UpdateContact = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContactStatus = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/status`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContactParticular = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/particular`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteContact = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`contact/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const SetContact = (data) => {
	const barcode = data.barcode;
	let defaultContact = find(data.persons, { default: true }) || new Object();
	if (!defaultContact) {
		defaultContact = head(data.persons) || new Object();
	}

	const contact = data.contact;

	const contact_person = data.contact_person;

	return new Object({ barcode, contact, contact_person });
};

export const CreateBillingAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`contact/${uuid}/address/billing`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateShippingAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`contact/${uuid}/address/shipping`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetAddress = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/${uuid}/address`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/${uuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateAddress = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/address/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateStatusAddress = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/address/${uuid}/status`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteAddress = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`contact/address/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateOrUpdateContactPerson = (type, uuid, params) => {
	if (type == "supplier") {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`supplier/contact/${uuid}/person`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`contact/${uuid}/person`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	}
};
// http://127.0.0.1:8000/api/v1/supplier/contact/5bfeef6a-fca7-4344-8b1f-9f8ed2abb8d2/address
export const UpdateSupplierAddress = (type, uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`${type}/${uuid}/address`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const CreateContactPerson = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`contact/${uuid}/person`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetContactPerson = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`contact/${uuid}/person`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateContactPerson = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/person/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const MarkAsDefaultContactPerson = (uuid, type = "contact") => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`${type}/person/${uuid}/default`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateStatusContactPerson = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`contact/person/${uuid}/status`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteContactPerson = ({ url, uuid }) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`${url}/person/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
