<template>
	<div class="address-book px-3 py-3 overflow-y" style="max-height: calc(100vh - 285px)">
		<template v-if="contentLoaded">
			<v-layout class="border-bottom-light-grey pb-2">
				<v-flex class="font-level-3-bold my-auto">
					<span class="detail-svg-icon mr-2">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/location-color.svg')" />
						<!--end::Svg Icon-->
					</span>
					<template>Addresses</template>
				</v-flex>
				<v-flex class="text-right">
					<v-btn color="blue darken-4 text-white" class="ml-2" depressed tile @click="createNewAddress()"
						><v-icon small left>mdi-plus</v-icon>Add Address</v-btn
					>
				</v-flex>
			</v-layout>
			<v-row>
				<v-col v-for="(row, index) in addressList" :key="index" md="4" class="py-3">
					<div class="p-4 border-light-grey" style="min-height: 126px">
						<v-layout>
							<v-flex md10 class="d-flex align-center">
								<p class="m-0 font-level-2-bold mr-3">Address #{{ row.barcode }}</p>
								<v-chip v-if="row.primary == 1" color="green white--text" label>Billing Address </v-chip>
								<v-chip v-else color="light-blue lighten-4 light-blue--text text--darken-4" label
									>Service Location
								</v-chip>
							</v-flex>
							<v-flex md2 class="text-right">
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-on="on" v-bind="attrs" color="blue darken-4">mdi-dots-vertical</v-icon>
									</template>

									<v-list>
										<v-list-item v-if="getPermission('customer::edit')" v-on:click="updateAddress(row, true)">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit</v-list-item-title
											>
										</v-list-item>
										<v-list-item
											v-if="getPermission('customer::edit') && !row.primary"
											v-on:click="markAsBillingAddress(row.uuid)"
										>
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-map-marker</v-icon>
												Mark as billing address</v-list-item-title
											>
										</v-list-item>
										<template v-if="addressList.length > 1">
											<v-list-item
												v-if="getPermission('customer::edit') && !row.primary"
												v-on:click="deleteAddress(row, true)"
												class="red--text text--lighten-1"
											>
												<v-list-item-title class="red--text text--lighten-1">
													<v-icon small left color="red lighten-1">mdi-delete</v-icon>
													Delete</v-list-item-title
												>
											</v-list-item>
										</template>
									</v-list>
								</v-menu>
							</v-flex>
						</v-layout>
						<v-layout>
							<!-- <v-flex md1>
								<v-icon color="blue darken-4">mdi-map-marker-outline</v-icon>
							</v-flex> -->
							<v-flex md11>
								<p class="m-0">
									<v-icon left small>mdi-tag</v-icon>
									<ShowValue :object="row" object-key="label" label="Label"></ShowValue>
								</p>
								<p class="m-0">
									<v-icon left small>mdi-account</v-icon>
									<ShowValue :object="row" object-key="name" label="display name"></ShowValue>
								</p>
								<p class="m-0">
									<v-icon left small>mdi-phone</v-icon>
									<ShowValue :object="row" object-key="phone_no" label="phone no"></ShowValue>
								</p>
								<p class="m-0">
									<v-icon left small>mdi-email</v-icon>
									<ShowValue :object="row" object-key="email" label="email"></ShowValue>
								</p>
								<div class="d-flex align-items-start">
									<v-icon left small>mdi-map-marker-outline</v-icon>
									<div>
										<p class="m-0" v-if="row && row.address_line_1">
											<ShowValue :object="row" object-key="address_line_1" label="address line 1"></ShowValue>
										</p>
										<p class="m-0" v-if="row && row.address_line_2">
											<ShowValue :object="row" object-key="address_line_2" label="address line 2"></ShowValue>
										</p>
										<p class="m-0" v-if="row && row.unit_number">
											<ShowValue :object="row" object-key="unit_number" label="unit number"></ShowValue>
										</p>
										<p class="m-0">
											<template v-if="row && row.address_country">
												<ShowValue :object="row" object-key="address_country" label="country"></ShowValue>
											</template>
											<template class="m-0" v-if="row && row.address_postal_code">
												&nbsp;<ShowValue
													:object="row"
													object-key="address_postal_code"
													label="postal code"
												></ShowValue>
											</template>
										</p>
									</div>
								</div>
							</v-flex>
						</v-layout>
					</div>
				</v-col>
			</v-row>

			<Dialog :dialog="addressDialog" :dialog-width="dialogWidth" v-if="addressDialog">
				<template v-slot:title>
					<template v-if="newAddress"> Add New </template>
					<template v-else> Update </template>
					Address
				</template>
				<template v-slot:body>
					<v-form
						ref="addressForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="onSubmit()"
					>
						<v-checkbox
							v-if="false"
							class="mx-3 mt-0 pt-0"
							hide-details
							dense
							v-model="address.default"
							:true-value="1"
							:false-value="0"
						>
							<template v-slot:label>
								Set as default
								<TooltipQuestion>
									<template v-slot:text
										>Address set as default will be<br />used as a Shipping Address</template
									>
								</TooltipQuestion>
							</template>
						</v-checkbox>
						<AddressTemplate
							is-member
							:type="typeText == 'Customer' ? 2 : 1"
							:disabled="pageLoading"
							:uuid="typeUuid"
							:loading="pageLoading"
							v-model="address"
							:customer-id="customerId"
							:new-address="newAddress"
							v-on:updateContactPersonList="getContactPersons"
						>
						</AddressTemplate>
					</v-form>
				</template>
				<template v-slot:action>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog()"> Close </v-btn>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onSubmit()"
					>
						Save
					</v-btn>
				</template>
			</Dialog>
			<DeleteTemplate
				type="Address"
				:delete-text="deleteText"
				v-on:success="
					deleteAddress({}, false);
					getAddress();
				"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
			>
			</DeleteTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
	UpdateAddress,
	CreateAddress,
	GetAddress,
	MarkAsDefaultAddress,
} from "@/core/lib/member.lib";
import { ValidateContact } from "@/core/lib/contact.lib";
import Dialog from "@/view/components/Dialog";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import AddressTemplate from "@/view/components/Address";
import TooltipQuestion from "@/view/components/TooltipQuestion";

export default {
	name: "member-address-book",
	title: "Address Book Member",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		customerId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			newAddress: false,
			deleteText: null,
			deleteURL: null,
			formValid: true,
			contentLoaded: true,
			deleteDialog: false,
			addressDialog: false,
			pageLoading: false,
			addressList: [
				/* {
					name: "John Parker",
					company_name: "bthrust pte ltd",
					phone_no: "856985698",
					email: "john@bthrust..com",
					address_line_1: "Bthrust",
					address_line_2: "Bthrust",
					address_postal_code: "896585",
					address_country: "Singapore",
					default: true,
				}, */
			],
			address: {
				address_city: null,
				address_country: "Singapore",
				address_line_1: null,
				address_line_2: null,
				address_postal_code: null,
				address_state: null,
				unit_number: null,
				default: 0,
				display_name: null,
				description: null,
				location: null,
				company_name: null,
				phone_no: null,
				email: null,
				name: null,
				address_type: 1,
				uuid: null,
				id: null,
			},
		};
	},
	watch: {
		newAddress(param) {
			if (param) {
				this.initAddress({ address_country: "Singapore" }, true);
			}
		},
	},
	methods: {
		getContactPersons() {
			this.$emit("updateContactPersonList", true);
		},
		markAsBillingAddress(uuid) {
			MarkAsDefaultAddress(uuid)
				.then(() => {
					this.getAddress();
					// this.$emit('reload:address',true);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				});
		},
		getAddress() {
			GetAddress(this.typeUuid, this.type)
				.then((data) => {
					this.addressList = data;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.contentLoaded = true;
				});
		},
		initAddress(row, dialog) {
			this.address.address_city = row.address_city ? row.address_city : null;
			this.address.address_country = row.address_country ? row.address_country : null;
			this.address.address_line_1 = row.address_line_1 ? row.address_line_1 : null;
			this.address.address_line_2 = row.address_line_2 ? row.address_line_2 : null;
			this.address.address_postal_code = row.address_postal_code ? row.address_postal_code : null;
			this.address.contact_person = row.contact_person ? row.contact_person : null;
			this.address.address_state = row.address_state ? row.address_state : null;
			this.address.default = row.default ? row.default : 0;
			this.address.primary = row.primary ? row.primary : 0;
			this.address.description = null;
			this.address.display_name = row.name ? row.name : null;
			this.address.unit_number = row.name ? row.unit_number : null;
			this.address.label = row.name ? row.label : null;
			this.address.location = null;
			this.address.company_name = row.company_name ? row.company_name : null;
			this.address.phone_no = row.phone_no ? row.phone_no : null;
			this.address.email = row.email ? row.email : null;
			this.address.name = row.name ? row.name : null;
			this.address.address_type = row.address_type ? row.address_type : null;
			this.address.uuid = row.uuid ? row.uuid : null;
			this.address.id = row.id ? row.id : null;

			this.$nextTick(() => {
				this.addressDialog = dialog;
			});
		},
		deleteAddress(row, dialog) {
			this.deleteText = row.uuid ? row.name : null;
			this.deleteURL = row.uuid
				? `${this.type}/${this.typeUuid}/delete-contact-address/${row.uuid}/address`
				: null;
			this.deleteDialog = dialog;
		},
		async onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.addressForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.addressForm.validate()) {
				return false;
			}

			/*  */
			const _address = [
				{
					id: _this.address.id,
					fields: [
						{
							field: "display_name",
							value: _this.address.display_name,
						},
						{
							field: "phone_number",
							value: _this.address.phone_no,
						},
						{
							field: "email",
							value: _this.address.email,
						},
					],
				},
			];
			const validatePayload = {
				id: this.newAddress ? null : this.customerId,
				filter_type: "address",
				fields: null,
				data: _address,
			};
			const { exists, error_message } = await ValidateContact("customer", validatePayload);
			if (exists) {
				if (error_message) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error_message }]);
				}
				return false;
			}
			/*  */

			// _this.address.address_type = 2;
			const formData = { address: [_this.address] };
			try {
				_this.pageLoading = true;
				if (formData.address.uuid) {
					await UpdateAddress(_this.typeUuid, _this.type, formData.uuid, formData);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Address has been updated." },
					]);
				} else {
					let msg = "created";
					if (formData.address[0].uuid) msg = "updated";
					await CreateAddress(_this.typeUuid, _this.type, formData);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Address has been " + msg + "." },
					]);
				}
				_this.initAddress({}, false);
				_this.getAddress();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		createNewAddress() {
			this.newAddress = true;
			this.initAddress({}, true);
		},
		updateAddress(row, dialog) {
			this.newAddress = false;
			this.initAddress(row, dialog);
		},
		closeDialog() {
			this.$emit("close", true);
			this.addressDialog = false;
		},
	},
	components: {
		Dialog,
		ShowValue,
		TooltipQuestion,
		DeleteTemplate,
		AddressTemplate,
	},
	mounted() {
		this.getAddress();
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			if (document.body.clientWidth < 992) {
				return toSafeInteger((document.body.clientWidth / 100) * 65);
			} else {
				return 800;
			}
		},
	},
};
</script>
