<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/event-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Logs
			</v-flex>
		</v-layout>
		<Loading v-if="contentLoading"></Loading>
		<div v-else class="overflow-y" style="max-height: calc(100vh - 306px)">
			<v-timeline v-if="events.length" dense>
				<v-timeline-item v-for="(row, index) in events" :key="index" color="grey" small>
					<template v-slot:icon>
						<v-avatar style="border: 2px solid #e0e0e0">
							<ImageTemplate circle :src="row.created_by_image"></ImageTemplate>
						</v-avatar>
					</template>
					<v-col class="py-0">
						<v-row justify="space-between">
							<v-col cols="7">
								<p class="mb-0">
									<span v-html="row.description"></span>
									<span class="text-muted"> by {{ row.user_name }}</span>
								</p>
								<p class="mb-0">
									IP : <span class="bold-400">{{ row.ip_address }}</span>
								</p>
							</v-col>
							<v-col class="text-right" cols="5">
								<v-tooltip left content-class="custom-left-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-chip v-bind="attrs" v-on="on" color="blue darken-4" text-color="white">{{
											formatDateTime(row.added_at)
										}}</v-chip>
									</template>
									<span> {{ formatDateTime(row.added_at) }} </span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-col>
				</v-timeline-item>
			</v-timeline>
			<p v-else class="m-0 row-not-found text-center">
				<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
				Uhh... There are no event at the moment.
			</p>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ImageTemplate from "@/view/components/Image";
import Loading from "@/view/components/Loading";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			events: [],
			contentLoading: false,
			pageLoading: true,
		};
	},
	methods: {
		getEvents() {
			this.contentLoading = true;
			ApiService.get(`activity-logs/${this.type}/${this.typeUuid}`)
				.then(({ data }) => {
					this.events = data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.contentLoading = false;
					this.pageLoading = false;
				});
		},
	},
	components: {
		ImageTemplate,
		Loading,
	},
	mounted() {
		this.getEvents();
	},
};
</script>
