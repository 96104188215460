<template>
	<v-row class="px-4">
		<v-col md="3" class="my-auto py-0">
			<label for="address-line-1" class="btx-label mt-2"
				>Address Line 1
				<TooltipQuestion v-if="false">
					<template v-slot:text
						>This name will be displayed on<br />the transactions you create for<br />this
						address</template
					>
				</TooltipQuestion>
			</label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-line-1"
				placeholder="Address Line 1"
				v-model="address.address_line_1"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-line-2" class="btx-label mt-2">Address Line 2 </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-line-2"
				placeholder="Address Line 2"
				v-model="address.address_line_2"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="unit-number" class="btx-label mt-2">Unit No. </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="unit-number"
				placeholder="Unit No."
				v-model="address.unit_number"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="postal-code" class="btx-label mt-2 required">Postal Code </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="postal-code"
				placeholder="Postal Code"
				type="number"
				v-model="address.address_postal_code"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0">
			<label for="address-country" class="btx-label mt-2">Country </label>
		</v-col>
		<v-col md="9" class="py-0">
			<TextInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-country"
				placeholder="Country"
				v-model="address.address_country"
			></TextInput>
		</v-col>
		<v-col md="3" class="my-auto py-0" v-if="false">
			<label for="address-phone-no" class="btx-label mt-2 required">Phone</label>
		</v-col>
		<v-col md="9" class="py-0" v-if="false">
			<PhoneTemplate
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-phone-no"
				placeholder="Phone No."
				v-model="address.phone_no"
				:rules="[
					vrules.required(address.phone_no, 'Phone No'),
					vrules.phoneNumber(address.phone_no, 'Phone No', 12),
				]"
				:class="{ required: !address.phone_no }"
			></PhoneTemplate>
		</v-col>
		<v-col md="3" class="my-auto py-0" v-if="false">
			<label for="address-email" class="btx-label mt-2 required">Email Address</label>
		</v-col>
		<v-col md="9" class="py-0" v-if="false">
			<EmailInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				id="address-email"
				placeholder="Email Address"
				v-model="address.email"
				:rules="[vrules.required(address.email, 'Email Address')]"
				:class="{ required: !address.email }"
			></EmailInput>
		</v-col>
		<v-col md="3" class="mt-2 py-0" v-if="false">
			<label :for="`address-street-1-${stringId}`" class="btx-label mt-2 required">Address</label>
		</v-col>
		<v-col md="9" class="py-0" v-if="false">
			<TextAreaInput
				hide-details
				:id="`address-street-1-${stringId}`"
				placeholder="Address"
				v-model="address.address_line_1"
				:disabled="pageLoading"
				:rules="[vrules.required(address.address_line_1, 'Address')]"
				:loading="pageLoading"
				:class="{
					required: !address.address_line_1,
				}"
			></TextAreaInput>
		</v-col>
	</v-row>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import EmailInput from "@/view/components/EmailInput";
import PhoneTemplate from "@/view/components/Phone";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ApiService from "@/core/services/api.service";
import { isObject, isEqual } from "lodash";

export default {
	name: "btx-address",
	title: "Address",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Object, String],
			default: null,
		},
		isLocation: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: Number,
			default: 1,
		},
		contactPersonList: {
			type: [Object, Array],
			default: new Array(),
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			countries: [],
			states: [],
			cities: [],
			contactPersons: [],
			locationList: [],
			countryLoading: false,
			contactPersonDialog: false,
			stateLoading: false,
			cityLoading: false,
			pageLoading: false,
			address: {
				id: null,
				uuid: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				address_country: "Singapore",
				address_postal_code: null,
			},
		};
	},
	watch: {
		address: {
			deep: true,
			handler(param) {
				this.$emit("input", param);
			},
		},
		value: {
			deep: true,
			handler() {
				this.updateAddress();
			},
		},
	},
	methods: {
		getContactPersonList() {
			this.$emit("updateContactPersonList", true);
		},
		updateAddress() {
			if (isObject(this.value) && !isEqual(this.value, this.address)) {
				this.address = {
					id: this.value.id || null,
					uuid: this.value.uuid || null,
					address_line_1: this.value.address_line_1 || null,
					address_line_2: this.value.address_line_2 || null,
					unit_number: this.value.unit_number || null,
					address_country: this.value.address_country || null,
					address_postal_code: this.value.address_postal_code || null,
					description: this.value.description || null,
					has_lat_long: this.value.has_lat_long || 0,
				};
			}
			this.$nextTick(() => {
				if (this.address.address_country) {
					this.getStates();
					if (this.address.address_state) {
						this.getCities();
					}
				}
			});
		},
		getCountries() {
			const countries = localStorage.getItem("countries");
			if (countries) {
				this.countries = JSON.parse(countries);
			} else {
				this.countries = [
					{
						name: "Singapore",
						code: "SG",
						dial_code: "+65",
					},
				];
				localStorage.setItem("countries", JSON.stringify(this.countries));
			}
		},
		getStates() {
			const { address_country } = this.address;
			const key = `states-${address_country}`.toLowerCase();
			const state = localStorage.getItem(key);
			if (state) {
				this.states = JSON.parse(state);
			} else {
				if (this.stateLoading) {
					return false;
				}
				this.stateLoading = true;
				ApiService.post("https://countriesnow.space/api/v0.1/countries/states", {
					country: address_country,
				})
					.then(({ data }) => {
						const { states } = data;
						this.states = states;
						localStorage.setItem(key, JSON.stringify(states));
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.stateLoading = false;
					});
			}
		},
		getCities() {
			const { address_country, address_state } = this.address;
			const key = `cities-${address_country}-${address_state}`.toLowerCase();
			const cities = localStorage.getItem(key);
			if (cities) {
				this.cities = JSON.parse(cities);
			} else {
				if (this.cityLoading) {
					return false;
				}
				this.cityLoading = true;
				ApiService.post("https://countriesnow.space/api/v0.1/countries/state/cities", {
					country: address_country,
					state: address_state,
				})
					.then(({ data }) => {
						this.cities = data;
						localStorage.setItem(key, JSON.stringify(data));
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.cityLoading = false;
					});
			}
		},
	},
	components: {
		TextInput,
		EmailInput,
		PhoneTemplate,
		TooltipQuestion,
		TextAreaInput,
	},
	computed: {
		...mapGetters(["localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.locationList = this.localDB("locations", []);
		this.updateAddress();
		this.getCountries();
		this.address.address_country = "Singapore";
	},
};
</script>
