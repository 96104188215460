<template>
	<div class="text-uppercase d-inline-block">
		<template v-if="tooltip">
			<v-tooltip top content-class="custom-top-tooltip">
				<template v-slot:activator="{ on, attrs }">
					<v-chip
						:class="customClass"
						:color="color"
						:outlined="outlined"
						:small="small"
						:label="label"
						v-bind="attrs"
						v-on="on"
					>
						{{ text }}
					</v-chip>
				</template>
				<span>
					{{ tooltipText }}
				</span>
			</v-tooltip>
		</template>
		<template v-else>
			<v-chip :class="customClass" :color="color" outlined :small="small" label>
				{{ text }}
			</v-chip>
		</template>
	</div>
</template>

<script>
export default {
	name: "btx-chip",
	props: {
		color: {
			type: String,
			default: "cyan",
		},
		textColor: {
			type: String,
			default: "white",
		},
		text: {
			type: String,
			default: null,
		},
		tooltipText: {
			type: String,
			default: null,
		},
		customClass: {
			type: String,
			default: null,
		},
		small: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: Boolean,
			default: false,
		},
		label: {
			type: Boolean,
			default: true,
		},
		outlined: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
