<template>
	<div>
		<v-text-field
			:id="id"
			:label="label"
			:loading="loading"
			:disabled="disabled"
			:hide-details="hideDetails"
			:append-icon="appendIcon"
			outlined
			:rules="
				rules.concat([
					vrules.validEmail(textinput, 'Email Address'),
					validationField && validationField.url_type && fieldNameError ? false : true,
				])
			"
			class="pt-0"
			:class="{
				'mt-3': !hideTopMargin,
			}"
			v-model="textinput"
			:placeholder="placeholder"
			v-on:change="
				validateEmailName();
				$emit('onChange', true);
			"
			v-on:keyup="$emit('keyup', true)"
			v-on:keydown="$emit('keydown', $event), sanitizeSpecialCharacter($event)"
		></v-text-field>
		<span
			v-if="fieldNameError"
			v-html="fieldNameError"
			class="red--text text--darken-1 font-small"
		></span>
	</div>
</template>
<script>
import { ValidateInputField } from "@/core/lib/common.lib";
export default {
	name: "text-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		validationField: {
			type: Object,
			default: () => {
				return {};
			},
		},
		placeholder: {
			type: String,
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
		parentId: {
			type: [String, Number],
			default: null,
		},
		currentId: {
			type: [String, Number],
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		appendIcon: {
			type: String,
			default: null,
		},
		validated: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textinput: null,
			fieldNameError: null,
		};
	},
	watch: {
		value() {
			this.textinput = this.value;
		},
		textinput() {
			this.$emit("input", this.textinput);
		},
	},
	methods: {
		validateEmailName() {
			const _this = this;
			/* For Remove validation start */
			const validate = true;
			if (validate) {
				return false;
			}
			/* For Remove validation end*/
			_this.fieldNameError = null;
			if (this.validationField && !this.validationField.url_type) {
				return false;
			}
			if (!_this.textinput) {
				return false;
			}
			return new Promise((resolve, reject) => {
				_this.pageLoading = true;
				ValidateInputField(`validate/${this.validationField.url_type}/any-field`, {
					filter_type:
						_this.validationField && _this.validationField.filter_type
							? _this.validationField.filter_type
							: null,
					field:
						_this.validationField && _this.validationField.field ? _this.validationField.field : null,
					value: _this.textinput,
					id: this.currentId,
					parentId: this.parentId,
				})
					.then((output) => {
						if (output.exists) {
							_this.fieldNameError = `<b class='fw-600'>${_this.textinput}</b> already exists`;
							resolve(true);
						} else {
							resolve(false);
						}
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		sanitizeSpecialCharacter($event) {
			let textInput = String($event.key);

			//eslint-disable-next-line
			const specialCharactersRegex = /[!#$%^&*()\+=\[\]{}|\\\/:;"'<>,?~`÷×≠≤≥®©™°µ€¥£§]/;
			if (specialCharactersRegex.test(textInput)) {
				$event.preventDefault();
			}
		},
	},
	mounted() {
		this.textinput = this.value;
	},
};
</script>
